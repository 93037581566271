import React from 'react';
import FuncionPrecio from 'functions/FuncionPrecio';

const ListaPedido = ({ carrito }) => {

    return (
        <>
            {
                carrito.length === 0 ?
                    <h1>No hay productos</h1>
                    :
                    carrito.map((producto, index) => {
                        return (
                            < div className="row cart-row" key={index}>
                                <div className="col-lg-8 col-md-8 col-sm-8 product-name">
                                    {producto.ART_NOMBRE}
                                    {/* {producto.ART_ATRIBUTO1 !== '' &&
                                        <> - {producto.ART_ATRIBUTO1_NOMBRE}: {producto.ART_ATRIBUTO1}</>
                                    } */}
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 quantity">x{producto.DOD_CANTIDAD}</div>
                                <div className="col-lg-3 col-md-3 col-sm-3 product-price right">
                                    {FuncionPrecio(producto.DOD_IMPORTE + producto.DOD_TRASLADADO, 0, 0)}
                                </div>
                            </div>
                        )
                    })

            }
        </>
    );
}

export default ListaPedido;