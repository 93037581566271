import React from 'react';
import IconCruz from 'images/cross.png';
import FuncionPrecio from 'functions/FuncionPrecio';
import { useShop } from 'context/ShopContext';
import { EliminarProductoCarrito } from 'functions/FuncionesCarrito';

const ListaCarrito = ({ carrito }) => {
    const { cambiarCarrito } = useShop();
    console.log(carrito);
    return (
        <>
            {carrito !== undefined && carrito.map((producto, index) => {
                return (
                    <div className="row cart-row" key={index} data-aos="fade-up" data-aos-delay={index > 5 ? '100' : `${index}00`}>
                        <div className="col-xs-12 col-sm-5 col-md-6 col-lg-7">
                            <div className="row">
                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                    <ul className="actions">
                                        <li>
                                            <button className="btn-clean" onClick={() => EliminarProductoCarrito(producto.ART_ID, carrito, cambiarCarrito)}>
                                                <img src={IconCruz} alt="cruz" />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <img src={producto.PRO_IMAGEN ? producto.PRO_IMAGEN : 'https://via.placeholder.com/80x91'} alt={producto.PRO_NOMBRE} className="img-responsive img80" />
                                </div>
                                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                                    <h1 className="product-name">{producto.PRO_NOMBRE}</h1>
                                    {producto.ART_ATRIBUTO1 !== '' &&
                                        <small><b>{producto.ART_ATRIBUTO1_NOMBRE}:</b> {producto.ART_ATRIBUTO1}</small>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-7 col-md-6 col-lg-5">
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="product-price">{FuncionPrecio(producto.PRO_PRECIO, producto.PRO_IMPUESTO, producto.PRO_DESCUENTO1)}</div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                                    <div className="product-price">{producto.DOD_CANTIDAD}</div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="total-product-price">{FuncionPrecio(producto.PRO_PRECIO * producto.DOD_CANTIDAD, producto.PRO_IMPUESTO, producto.PRO_DESCUENTO1)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </>

    );
}

export default ListaCarrito;