const ReglasInput = {
    'nombre': {
        required: { value: true, message: 'Es necesario agregar tu Nombre Completo.' },
        maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
        minLength: { value: 10, message: 'El Nombre Completo no puede ser menor de 10 Caracteres.' },
        pattern: { value: !/^[a-zA-ZÀ-ÿ\s]{1,40}$/, message: 'El Nombre contiene caracteres No Validos.' }
    },
    'correo': {
        required: { value: true, message: 'Es necesario agregar tu Correo Electrónico.' },
        maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
        pattern: { value: !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: 'El correo no es valido.' }
    },
    'celular': {
        required: { value: true, message: 'Es necesario agregar tu Teléfono ó Celular' },
        maxLength: { value: 13, message: 'Ingresa el número a 10 Digitos.' },
        pattern: { value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, message: 'El número no es valido.' }
    },
    'razonsocial': {
        maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
        minLength: { value: 10, message: 'La Razón Social no puede ser menor de 10 Caracteres.' },
    },
    'rfc': {
        maxLength: { value: 13, message: 'El RFC no puede superar los 13 Caracteres' },
        minLength: { value: 12, message: 'El RFC no puede ser menor a 12 Caracteres' },
    },
    'calle': {
        required: { value: true, message: 'Es necesario agregar tu Calle y Número.' },
        maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
        minLength: { value: 5, message: 'El campo no admite menos de 5 Caracteres.' },
    },
    'cp': {
        required: { value: true, message: 'Es necesario agregar tu Código Postal.' },
        maxLength: { value: 5, message: 'El Código Postal deben ser 5 digitos.' },
        minLength: { value: 5, message: 'El Código Postal deben ser 5 digitos.' },
    },
    'colonia': {
        required: { value: true, message: 'Es necesario agregar tu Colonia.' },
    },
    'ciudad': {
        required: { value: true, message: 'Es necesario agregar tu Ciudad.' },
    },
    'estado': {
        required: { value: true, message: 'Es necesario agregar tu Estado.' },
    },
}

export default ReglasInput