import React from 'react';

const ComoLoHacemos = () => {
    return (
        <div className="container-fluid what-we-do">
            <div className="row no-gutter-6">
                <div className="col-lg-6 col-md-6 col-sm-6 pic" data-aos="fade-right" data-aos-delay="100">
                    <img src="https://res.cloudinary.com/labouche/image/upload/v1634604922/imagenes_fijas/inicio/como-lo-hacemos_ntinkz.jpg" className="img-responsive w-100" alt="algo" />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-5 col-lg-offset-1 col-md-offset-1 contents">
                    <h1 data-aos="fade" data-aos-delay="200">
                        COMO LO <span>HACEMOS</span>
                    </h1>
                    <div className="description text-justify" data-aos="fade" data-aos-delay="200">
                    Iniciamos diseñando y fabricando joyería en plata .925, pero este material marcaba la diferencia y se comportaba según la piel de la mujer que la portara, reaccionando a distintos elementos como la piel, temperatura y otros aspectos del entorno. Deseábamos que nuestra joyería pudiera ser lucida por todas las mujeres sin importar las características y circunstancias del entorno. 
                    </div>
                    <div className="description text-justify" data-aos="fade" data-aos-delay="200">
                    Fue entonces, cuando logramos alcanzar nuestro sueño, tomando la decisión de fabricar joyería que pudiera ser manipulada, expuesta, manejada y lucida bajo cualquier circunstancia y entorno, sin tener ningún efecto negativo tanto en la piel, como en la joya misma.
                    </div>
                    <div className="description text-justify" data-aos="fade" data-aos-delay="200">
                    <b>A partir de ese momento, hemos diseñado y fabricado joyería fina en acero inoxidable de calidad quirúrgico. </b>
                    </div>
                    {/* Progress Bars Starts */}
                    <div className="progress-bars" data-aos="fade" data-aos-delay="400">
                        {/* Block Starts */}
                        <div className="block">
                            <div className="progress-label">
                                <span className="caption">CALIDAD</span>
                            </div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "92%" }}
                                ></div>
                            </div>
                        </div>
                        {/* Block Ends */}
                        {/* Block Starts */}
                        <div className="block">
                            <div className="progress-label">
                                <span className="caption">DISEÑO</span>
                            </div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "95%" }}
                                ></div>
                            </div>
                        </div>
                        {/* Block Ends */}
                        {/* Block Starts */}
                        <div className="block">
                            <div className="progress-label">
                                <span className="caption">ELEGANCIA</span>
                            </div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "92%" }}
                                ></div>
                            </div>
                        </div>
                        {/* Block Ends */}
                    </div>
                    {/* Progress Bars Ends */}
                </div>
            </div>
        </div>

    );
}

export default ComoLoHacemos;