import React from "react";
import Slider from "react-slick";
import 'components/inicio/Banner.css';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear"
    };

    return (
        <>
            <Slider {...settings} className="contenedor-slider">
                <div className="slider imagen-1"></div>
                <div className="slider imagen-2"></div>
                <div className="slider imagen-3"></div>
            </Slider>
            <div className="slider">
                <div className='btn-slider'>
                    <Link to="/tienda" className='btn btn-default'>COMPRAR AHORA</Link>
                </div>
            </div>
        </>

    );
}

export default Banner;