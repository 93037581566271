import { useState, useEffect } from 'react'
import axios from 'axios';

const useObtenerListaProductos = ({ filtros, cambiarCargandoProductos }) => {
    const [listaDatos, cambiarListaDatos] = useState([]);
    
    useEffect(() => {
        const URL = `${process.env.REACT_APP_CONFIG_BASE_URL}tienda_filtro/${process.env.REACT_APP_CONFIG_EMPRESA}/${filtros.pagina}`
        const Formulario = {
            categoria: filtros.categoria,
            linea: filtros.linea,
            texto: filtros.texto,
            elementos: 16,
            atributo1: filtros.atributo1,
            atributo2: filtros.atributo2,
            atributo3: filtros.atributo3,
            atributo4: filtros.atributo4,
            atributo5: filtros.atributo5,
            atributo6: filtros.atributo6,
        }
        // console.log(URL);
        // console.log(Formulario);
        // console.log(filtros);
        const PeticionDatos = () => {
            cambiarCargandoProductos(true);
            axios.post(URL, Formulario)
                .then(res => {
                    const respuesta = res.data;
                    cambiarListaDatos(respuesta);
                    setTimeout(() => {
                        cambiarCargandoProductos(false)
                    }, 500)
                })
                .catch(err => {
                    console.log(err);
                    
                })
        }

        return PeticionDatos();
    }, [filtros, cambiarCargandoProductos])

    return [listaDatos]
}

export default useObtenerListaProductos;