import React, { useEffect } from 'react';
import BannerCheckout from 'components/checkout/BannerCheckout';
import Formulario from 'components/checkout/Formulario';

const Checkout = () => {
    useEffect(() => { window.scrollTo({ top: 0 }) }, [])
    return (
        <>
            <BannerCheckout />
            <Formulario />
        </>
    );
}

export default Checkout;