import FormatoCantidad from 'functions/FormatoCantidad';

const FuncionPrecio = (PrecioSinIva, Iva, Descuento) => {
    let PrecioFinal = 0

    PrecioFinal = PrecioSinIva * (Iva === 0 ? 1 : (Iva + 1))
    PrecioFinal = PrecioFinal * (Descuento === 0 ? 1 : Descuento)
    
    return FormatoCantidad.format(Math.round(PrecioFinal))
}

export default FuncionPrecio;