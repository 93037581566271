import React, { useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useShop } from 'context/ShopContext';

const Menu = () => {
    const [overlayMenu, cambiarOverlayMenu] = useState(false)
    const history = useHistory();
    const { carrito } = useShop();
    const location = useLocation(); 

    const cambiarPagina = (url) => {
        history.push(url)
        cambiarOverlayMenu(false)
    }

    return (
        <>
            {location.pathname !== '/error' &&
                <>
                    <div className={location.pathname === '/inicio' ? 'navigation fill-black normal with-slider' : 'navigation fill-black normal'}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="logo">
                                        <Link to="/inicio">
                                            <img src="https://res.cloudinary.com/labouche/image/upload/v1634178734/imagenes_fijas/logotipos/logotipo-negro_cwib3b.png" style={{width:190}} className="img-responsive" alt="logotipo-labouche" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <ul className="links">
                                        <li>
                                            <button onClick={() => cambiarOverlayMenu(true)} type="button" className="btn-clean ">
                                                <i className="icon-menu icons" />
                                            </button>
                                        </li>
                                        <li className="shop_cart">
                                            <Link to="/carrito">
                                                <i className="icon-bag icons" />
                                                <span>{carrito.length}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /. HEADER ENDS
			========================================================================= */}
                    {/* NAVIGATION STARTS
			========================================================================= */}
                    <div
                        className={`overlay overlay-genie ${overlayMenu ? 'open' : 'close'}`}
                        data-steps="m 701.56545,809.01175 35.16718,0 0,19.68384 -35.16718,0 z;m 698.9986,728.03569 41.23353,0 -3.41953,77.8735 -34.98557,0 z;m 687.08153,513.78234 53.1506,0 C 738.0505,683.9161 737.86917,503.34193 737.27015,806 l -35.90067,0 c -7.82727,-276.34892 -2.06916,-72.79261 -14.28795,-292.21766 z;m 403.87105,257.94772 566.31246,2.93091 C 923.38284,513.78233 738.73561,372.23931 737.27015,806 l -35.90067,0 C 701.32034,404.49318 455.17312,480.07689 403.87105,257.94772 z;M 51.871052,165.94772 1362.1835,168.87863 C 1171.3828,653.78233 738.73561,372.23931 737.27015,806 l -35.90067,0 C 701.32034,404.49318 31.173122,513.78234 51.871052,165.94772 z;m 52,26 1364,4 c -12.8007,666.9037 -273.2644,483.78234 -322.7299,776 l -633.90062,0 C 359.32034,432.49318 -6.6979288,733.83462 52,26 z;m 0,0 1439.999975,0 0,805.99999 -1439.999975,0 z"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 1440 806"
                            preserveAspectRatio="none"
                        >
                            <path
                                className="overlay-path"
                                d="m 701.56545,809.01175 35.16718,0 0,19.68384 -35.16718,0 z"
                            />
                        </svg>
                        <button type="button" onClick={() => cambiarOverlayMenu(false)} className="overlay-close overlay-close-btn">
                            Close
                        </button>
                        <nav>
                            <ul>
                                <li>
                                    <span onClick={() => cambiarPagina('/inicio')} className="overlay-close-btn">
                                        Inicio
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => cambiarPagina('/equipo')} className="overlay-close-btn">
                                        Equipo
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => cambiarPagina('/tienda')} className="overlay-close-btn">
                                        Tienda
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => cambiarPagina('/contacto')} className="overlay-close-btn">
                                        Contacto
                                    </span>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/labouchemx/" target="_blank" rel="noreferrer">
                                        <i className="fa fa-instagram" aria-hidden="true"></i> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/labouche.mexico" target="_blank" rel="noreferrer">
                                        <i className="fa fa-facebook" aria-hidden="true"></i> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://vm.tiktok.com/ZM88HRyfV/" target="_blank" rel="noreferrer">
                                        <i className="fab fa-tiktok"></i> Tiktok
                                    </a>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </>
            }
        </>
    );
}

export default Menu;