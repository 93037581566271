import React, { useContext } from 'react';
import { AgregarCarrito } from 'functions/FuncionesCarrito';
import { useNotifications } from 'reapop';

//creamos el contexto
const ShopContext = React.createContext();

//Hook para acceder al contexto
const useShop = () => {
    return useContext(ShopContext);
}

const ShopProvider = ({ children, carrito, cambiarCarrito, subtotal, cambiarCargando, cambiarParametrosVistaRapida }) => {
    const { notify } = useNotifications();

    const FuncionAgregarCarrito = (record) => {
        const FueExitoso = AgregarCarrito(record, carrito, cambiarCarrito)
        return FueExitoso ?
            notify({ title: 'Producto Agregado con Éxito', message: `Se agrego el producto al carrito`, dismissible: true, dismissAfter: '3000', status: 'success' })
            : notify({ title: 'Ocurrió un Problema', message: `Por favor vuelve a intentarlo, si continua fallando contactanos.`, dismissible: true, dismissAfter: '3000', status: 'error' })
    }

    return (
        <ShopContext.Provider value={{ carrito, cambiarCarrito, subtotal, cambiarCargando, cambiarParametrosVistaRapida, FuncionAgregarCarrito }} >
            {children}
        </ShopContext.Provider>
    );
}

export { ShopProvider, ShopContext, useShop };