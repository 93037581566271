
// FUNCION: ELIMINAR FILTRO
const EliminarFiltros = (varNombreFiltro, filtros, cambiarFiltros) => {
    var Temporal = { ...filtros }
    switch (varNombreFiltro) {
        case 'categoria':
            Temporal['categoria'] = ''
            Temporal['linea'] = ''
            Temporal['atributo1'] = ''
            Temporal['atributo2'] = ''
            Temporal['atributo3'] = ''
            Temporal['atributo4'] = ''
            Temporal['atributo5'] = ''
            Temporal['atributo6'] = ''
            break;
        case 'linea':
            Temporal['linea'] = ''
            Temporal['atributo1'] = ''
            Temporal['atributo2'] = ''
            Temporal['atributo3'] = ''
            Temporal['atributo4'] = ''
            Temporal['atributo5'] = ''
            Temporal['atributo6'] = ''
            break;
        case 'atributo1':
            Temporal['atributo1'] = ''
            break;
        case 'texto':
            Temporal['texto'] = ''
            break;
        case 'atributo2':
            Temporal['atributo2'] = ''
            break;
        case 'atributo3':
            Temporal['atributo3'] = ''
            break;
        case 'atributo4':
            Temporal['atributo4'] = ''
            break;
        case 'atributo5':
            Temporal['atributo5'] = ''
            break;
        case 'atributo6':
            Temporal['atributo6'] = ''
            break;
        case 'todos':
            Temporal['categoria'] = ''
            Temporal['texto'] = ''
            Temporal['linea'] = ''
            Temporal['atributo1'] = ''
            Temporal['atributo2'] = ''
            Temporal['atributo3'] = ''
            Temporal['atributo4'] = ''
            Temporal['atributo5'] = ''
            Temporal['atributo6'] = ''
            break;

        default:
            break;
    }
    Temporal['pagina'] = 1
    cambiarFiltros(Temporal)
}

// FUNCION:  MODIFICAR FILTROS
const ModificarFiltros = (varNombreFiltro, filtros, cambiarFiltros, valor) => {
    var Temporal = { ...filtros }
    Temporal[varNombreFiltro] = valor
    Temporal['pagina'] = 1
    cambiarFiltros(Temporal)
}

// FUNCION ESPECIAL: PARA AGREGAR LINEA Y CATEGORIA DE GOLPE
const ModificarFiltrosCatLin = (filtros, cambiarFiltros, categoria, linea) => {
    var Temporal = { ...filtros }
    Temporal['categoria'] = categoria
    Temporal['linea'] = linea
    Temporal['pagina'] = 1
    cambiarFiltros(Temporal)
}

const ModificarPagina = (valor, filtros, cambiarFiltros) => {
    var Temporal = { ...filtros }
    Temporal['pagina'] = valor
    cambiarFiltros(Temporal)
}

export { EliminarFiltros, ModificarFiltros, ModificarFiltrosCatLin, ModificarPagina }