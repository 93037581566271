import React from 'react';
import ImgComillas from 'images/comillas.png';

const SomosUno = () => {
    return (
        <div className="container our-team">
            <div className="row">
                <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-delay="100">
                    <h1>
                        SOMOS <span>UNO</span>
                    </h1>
                    <div className="description">
                    Para crear algo extraordinario como lo es la joyería, se requieren personas extraordinarias, que en conjunto con sus distintas personalidades, formas de pensar, maneras de ver la vida, experiencias e ideas unicas puedan llegar a  un resultado que cumpla con el sueño de todos. Todos formamos una sola cabeza, y somos un engrane perfecto para el diseño, fabricación, difusión y proyección de una joya única. 
                    </div>
                </div>
                <div className="col-lg-8 col-md-8">
                    <div className="testimonial-carousel">
                        {/* Block Starts */}
                        <div className="block">
                            <div className="pic" data-aos="fade-up" data-aos-delay="200">
                                <img
                                    src="https://res.cloudinary.com/labouche/image/upload/v1633810963/imagenes_fijas/team/ceo_rq1ybi.jpg"
                                    className="img-responsive"
                                    alt="algo"
                                />
                            </div>
                            <div className="detail">
                                <div className="icon" data-aos="fade-up" data-aos-delay="300">
                                    <img
                                        src={ImgComillas}
                                        className="img-responsive"
                                        alt="algo"
                                    />
                                </div>
                                <div className="description" data-aos="fade-up" data-aos-delay="300">
                                Cada mujer tiene su esencia, y esa, se refleja en sus labios. Los labios es el distintivo de todo el cuerpo que nos puede decir mucho sobre su personalidad y deseos. 
                                </div>
                                <div className="name" data-aos="fade-up" data-aos-delay="300">Federico Juárez Bassol / CEO / Diseñador</div>
                                <div className="signature pull-right" data-aos="fade-up" data-aos-delay="400">
                                    <img
                                        src="https://res.cloudinary.com/labouche/image/upload/v1634004122/imagenes_fijas/team/firma_eklopf.png"
                                        className="img-responsive"
                                        alt="algo"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Block Ends */}

                    </div>
                </div>
            </div>
        </div>

    );
}

export default SomosUno;