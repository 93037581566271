import { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useObtenerDatosDocumento = ({ idDoc }) => {
  const [datosExtras, cambiarDatosExtras] = useState('');
  const history = useHistory();
  useEffect(() => {
    const URL = `${process.env.REACT_APP_CONFIG_BASE_URL}/tienda_pedido/${process.env.REACT_APP_CONFIG_EMPRESA}/${idDoc}`
    console.log(URL);
    const PeticionDatos = () => {
      if (idDoc !== 0) {
        axios.get(URL)
          .then(res => {
            const respuesta = res.data;
            if (respuesta.ESTATUS === 'ERROR') {
              history.push('/');
            } else {
              cambiarDatosExtras(respuesta);
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    }

    return PeticionDatos();
  }, [idDoc,history])

  return [datosExtras, cambiarDatosExtras]
}

export default useObtenerDatosDocumento;