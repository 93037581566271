const AgregarCarrito = (record, carrito, cambiarCarrito) => {
    // Si el carrito no tiene elementos entonces agregamos uno.
    if (carrito.length === 0) {
        cambiarCarrito([record]);
    } else {
        const nuevoCarrito = [...carrito];

        // Comprobamos si el carrito ya tiene el ID del producto a agregar.
        const yaEstaEnCarrito = nuevoCarrito.filter((productoDeCarrito) => {
            return productoDeCarrito.ART_ID === record.ART_ID
        }).length > 0;

        if (yaEstaEnCarrito) {
            nuevoCarrito.forEach((productoDeCarrito, index) => {
                if (productoDeCarrito.ART_ID === record.ART_ID) {
                    nuevoCarrito[index].DOD_CANTIDAD = productoDeCarrito.DOD_CANTIDAD + record.DOD_CANTIDAD
                    // MODIFICAMOS UNICAMENTE LA CANTIDAD DEL ARTICULO GUARDADO EN EL CARRITO
                }
            });
        } else {
            nuevoCarrito.push(record);
        }

        cambiarCarrito(nuevoCarrito);
    }
    return true
};

const EliminarProductoCarrito = (ArtId, carrito, cambiarCarrito) => {
    // Para poder editar el arreglo tenemos que clonarlo.
    const nuevoCarrito = [...carrito];

    nuevoCarrito.forEach((productoDeCarrito, index) => {
        if (productoDeCarrito.ART_ID === ArtId) {
            nuevoCarrito.splice(index, 1)
        }
    });
    // Por ultimo actualizamos el carrito.
    cambiarCarrito(nuevoCarrito);
};

const RestarProductoAlCarrito = (Id, carrito, cambiarCarrito) => {
    // Para poder editar el arreglo tenemos que clonarlo.
    const nuevoCarrito = [...carrito];

    nuevoCarrito.forEach((productoDeCarrito, index) => {
        if (productoDeCarrito.ART_ID === Id) {
            const cantidad = nuevoCarrito[index].DOD_CANTIDAD;
            if (cantidad === 1) {
                nuevoCarrito.splice(index, 1)
            } else {
                nuevoCarrito[index].DOD_CANTIDAD = cantidad - 1
            }
        }
    });
    // Por ultimo actualizamos el carrito.
    cambiarCarrito(nuevoCarrito);
};

const SumarProductoAlCarrito = (Id, carrito, cambiarCarrito) => {
    // Para poder editar el arreglo tenemos que clonarlo.
    const nuevoCarrito = [...carrito];

    nuevoCarrito.forEach((productoDeCarrito, index) => {
        if (productoDeCarrito.ART_ID === Id) {
            const cantidad = nuevoCarrito[index].DOD_CANTIDAD;
            nuevoCarrito[index].DOD_CANTIDAD = cantidad + 1
            // nuevoCarrito[index] = {
            //     PRO_ID: productoDeCarrito.PRO_ID,
            //     PRO_NOMBRE: productoDeCarrito.PRO_NOMBRE,
            //     PRO_PRECIO: productoDeCarrito.PRO_PRECIO,
            //     ART_ID: productoDeCarrito.ART_ID,
            //     ART_ATRIBUTO1: productoDeCarrito.ART_ATRIBUTO1,
            //     ART_ATRIBUTO2: productoDeCarrito.ART_ATRIBUTO2,
            //     PRO_DESCUENTO1: productoDeCarrito.PRO_DESCUENTO1,
            //     PRO_IMAGEN: productoDeCarrito.PRO_IMAGEN,
            //     PRO_IMPUESTO: productoDeCarrito.PRO_IMPUESTO,
            //     DOD_CANTIDAD: cantidad + 1
            // }
        }
    });
    // Por ultimo actualizamos el carrito.
    cambiarCarrito(nuevoCarrito);
};

const ProcesarCarritoParaPedido = (carrito, envio) => {
    const CarritoProcesado = []
    let Total = 0;

    // console.log(carrito);
    carrito.forEach((producto) => {
        Total = Total + ((producto.DOD_CANTIDAD * producto.PRO_PRECIO) * (1 + producto.PRO_IMPUESTO))

        CarritoProcesado.push(
            {
                DOD_CANTIDAD: producto.DOD_CANTIDAD,
                ART_ID: producto.ART_ID,
                PRO_PRECIO: producto.PRO_PRECIO,
                DOD_DESCUENTO: producto.PRO_DESCUENTO1 * producto.PRO_PRECIO,
                DOD_TRASLADADO: producto.PRO_IMPUESTO * producto.PRO_PRECIO
            }
        )
    })

    // TODO: FALTA AGREGAR INFO DEL PRODUCTO ENVIO
    if (envio) {
        CarritoProcesado.push(
            {
                DOD_CANTIDAD: 1,
                ART_ID: 1,
                PRO_PRECIO: 129.31,
                DOD_DESCUENTO: 0,
                DOD_TRASLADADO: 20.68
            }
        )
        Total = Total + 150
    }


    return { CarritoProcesado, Total };
}

const RecuperarCarrito = () => {
    const carritoGuardado = localStorage.getItem('carrito') ? JSON.parse(localStorage.getItem('carrito')) : [];
    return carritoGuardado
}

const GuardarCarritoLocal = (carrito) => {
    localStorage.setItem('carrito', JSON.stringify(carrito));
}

const ActualizarSubtotal = (carrito, cambiarSubtotal) => {
    var Subtotal = 0
    if (carrito.length !== 0) {
        carrito.forEach(producto => {
            Subtotal = Subtotal + (producto.DOD_CANTIDAD * ((producto.PRO_IMPUESTO === 0 ? 1 : 1 + producto.PRO_IMPUESTO) * (producto.PRO_PRECIO * (1 - producto.PRO_DESCUENTO1))))
        });
    }
    cambiarSubtotal(Subtotal)
}

export {
    AgregarCarrito,
    EliminarProductoCarrito,
    RestarProductoAlCarrito,
    SumarProductoAlCarrito,
    ProcesarCarritoParaPedido,
    RecuperarCarrito,
    GuardarCarritoLocal,
    ActualizarSubtotal
}