import React from 'react';
import Spinner from 'core/general/Spinner';

const Overlay = () => {
    return (
        <div className="bg-black-overlay">
            <div className='centrado-vertical text-white'>
                <Spinner />
            </div>
        </div>
    );
}

export default Overlay;