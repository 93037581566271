import React from 'react';
import { Link } from 'react-router-dom';
import FuncionPrecio from 'functions/FuncionPrecio';
import { useShop } from 'context/ShopContext';
import { useNotifications } from 'reapop';

const GridProductos = ({ listaProductos }) => {
    const { FuncionAgregarCarrito } = useShop();
    const { notify } = useNotifications();

    const ValidarAgregarCarrito = (producto) => {
        // notify({ title: 'Ocurrió un Problema', message: `Por favor vuelve a intentarlo, si continua fallando contactanos.`, dismissible: true, dismissAfter: '3000', status: 'error' })
        if (producto.ART_ID === 0) {
            return notify({  message: `Ingresa al Producto para seleccionar ${producto.LIN_ATRIBUTO_1}`, status: 'info', dismissible: true, dismissAfter: '3000' })
        }

        const record = {
            PRO_ID: producto.PRO_ID,
            PRO_NOMBRE: producto.PRO_NOMBRE,
            PRO_PRECIO: producto.PRO_PRECIO,
            PRO_DESCUENTO1: producto.PRO_DESCUENTO1,
            PRO_IMPUESTO: producto.PRO_IMPUESTO,
            PRO_IMAGEN: producto.IMAGEN_URL,
            ART_ID: producto.ART_ID,
            ART_ATRIBUTO1: '',
            ART_ATRIBUTO1_NOMBRE: '',
            UNM_NOMBRE: 'pza',
            DOD_CANTIDAD: parseInt(1),
        }

        FuncionAgregarCarrito(record)
    }

    return (
        <div className="row">
            {listaProductos.length !== 0 ?
                listaProductos.map((producto, index) => {
                    return (
                        <div className=" col-lg-4 col-md-4 product" key={index} data-aos="fade" data-aos-delay={`${index}00`}>
                            <div className="p-pic">
                                <img src={producto.IMAGEN_URL ? producto.IMAGEN_URL : 'https://via.placeholder.com/350X400'} className="img-responsive center-block" alt={producto.PRO_NOMBRE} />
                                <div className="product-overlay">
                                    <div className="icons">
                                        <div>
                                            <span className="icon mb-15">
                                                <Link to={`/producto/${producto.PRO_ID}`}>VER DETALLES</Link>
                                            </span>
                                            <span className="icon">
                                                <button className='btn-clean' onClick={() => ValidarAgregarCarrito(producto)}>AÑADIR AL CARRITO</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <Link to={`/producto/${producto.PRO_ID}`}>
                                    <div className="p-name max-renglon" title={producto.PRO_NOMBRE}>{producto.PRO_NOMBRE}</div>
                                </Link>
                                <div className="p-price">{FuncionPrecio(producto.PRO_PRECIO, producto.PRO_IMPUESTO, producto.PRO_DESCUENTO1)}</div>
                            </div>
                        </div>
                    )
                })
                :
                <h1>PRODUCTOS NO ENCONTRADOS</h1>
            }

        </div>

    );
}

export default GridProductos;