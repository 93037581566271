import React from 'react';

const Spinner = () => {
    return (
        <div className="text-center" data-aos="fade">
            <div className="lds-dual-ring"></div>
            <p>Cargando...</p>
        </div>
    );
}

export default Spinner;