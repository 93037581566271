import React from 'react';
import { Link } from 'react-router-dom';

const BannerProducto = ({PRODUCTO_NOMBRE, CAT_NOMBRE}) => {
    return (
        <div className="inner-banner image-productos" data-aos="fade">
            <div className="container">
                <ol className="breadcrumb">
                    <li data-aos="fade" data-aos-delay="200"><Link to="/tienda">Tienda</Link></li>
                    <li data-aos="fade" data-aos-delay="300"><Link to={`/tienda/c&=${CAT_NOMBRE}`}>{CAT_NOMBRE}</Link></li>
                    <li data-aos="fade" data-aos-delay="400" className="active">{PRODUCTO_NOMBRE}</li>
                </ol>
                <h1 data-aos="fade" data-aos-delay="600">{PRODUCTO_NOMBRE}</h1>
            </div>
        </div>
    );
}

export default BannerProducto;