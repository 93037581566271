import React, { useState, useEffect } from 'react';
import Banner from 'components/producto/Banner';
import InfoProducto from 'components/producto/InfoProducto';
import { useParams } from 'react-router-dom';
import useObtenerProducto from 'hooks/useObtenerProducto';
import Spinner from 'core/general/Spinner';
import GridProductos from 'components/tienda/GridProductos';

const Producto = () => {
    const { id } = useParams();
    const [cargandoProductos, cambiarCargandoProductos] = useState(false)
    const [producto] = useObtenerProducto({ id, cambiarCargandoProductos })
    useEffect(() => { window.scrollTo({ top: 0, }) }, [id])

    return (
        <>
            {cargandoProductos ?
                <div className="mt-250">
                    <Spinner />
                </div>
                :
                <>
                    <Banner PRODUCTO_NOMBRE={producto.PRO_NOMBRE} CAT_NOMBRE={producto.CAT_NOMBRE} />
                    <div className="container contents product-page">
                        <InfoProducto producto={producto} id={id} />
                        <div className="line"></div>
                        {producto.RELACIONADOS && producto.RELACIONADOS.length !== 0 &&
                            <>
                                <h1>Productos Relacionados</h1>
                                <GridProductos listaProductos={producto.RELACIONADOS} />
                            </>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Producto;