import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    return ( 
        <div className="inner-banner image-productos" data-aos="fade">
                <div className="container">
                    <ol className="breadcrumb">
                        <li data-aos="fade" data-aos-delay="200">
                            <Link to="/inicio">Inicio</Link>
                        </li>
                        <li data-aos="fade" data-aos-delay="400" className="active">Tienda</li>
                    </ol>
                    <h1 data-aos="fade" data-aos-delay="600">Nuestros Productos</h1>
                </div>
            </div>
     );
}
 
export default Banner;