import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const Aplicacion = () => {
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

ReactDOM.render(<Aplicacion />, document.getElementById('root'));
