import React from 'react';
import { useForm } from 'react-hook-form';
import { useNotifications } from 'reapop';
import axios from 'axios';
import { useShop } from 'context/ShopContext';

const FormularioContacto = ({ mensaje }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { notify } = useNotifications();
    const { cambiarCargando } = useShop();

    const ReglasInput = {
        'nombre': {
            required: { value: true, message: 'Es necesario agregar tu Nombre Completo.' },
            maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
            minLength: { value: 10, message: 'El Nombre Completo no puede ser menor de 10 Caracteres.' },
            pattern: { value: !/^[a-zA-ZÀ-ÿ\s]{1,40}$/, message: 'El Nombre contiene caracteres No Validos.' }
        },
        'correo': {
            required: { value: true, message: 'Es necesario agregar tu Correo Electrónico.' },
            maxLength: { value: 50, message: 'El campo no admite más de 50 Caracteres.' },
            pattern: { value: !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: 'El correo no es valido.' }
        },
        'nota': {
            required: { value: true, message: 'Es necesario agregar una Nota.' },
            maxLength: { value: 100, message: 'El campo no admite más de 100 Caracteres.' },
        },
    }

    const onSubmit = async (data) => {
        cambiarCargando(true);
        const formulario = {
            nombre: data.nombre, correo: data.correo,
            nota: data.nota === undefined ? 'Se suscribió para recibir noticias y promociones' : data.nota,
            dominio: 'caloja.com.mx', destino: 'ricardo_lomes@hotmail.com', password: 'Clarion6!!'
        }
        const notification = notify({ title: 'Enviando...', message: 'Estamos enviando tu formulario, espera un momento.', dismissible: false, status: 'loading' })
        const URL = 'https://caloja.ddns.net/tienda_contacto'
        await axios.post(URL, formulario)
            .then(res => {
                const respuestaAxios = res.data;
                if (respuestaAxios.ESTATUS === "OK") {
                    reset();
                    notification.status = 'success'; notification.title = '¡Enviado con Éxito!'; notification.message = mensaje ? 'Recibirás una respuesta lo más pronto posible.' : 'Recibirás noticias e información en tu correo.'; notification.dismissible = true; notification.dismissAfter = 5000;
                    notify(notification)
                } else {
                    notification.status = 'error'; notification.title = '¡Hubo un Problema!'; notification.message = 'No se pudo enviar tu formulario al servidor'; notification.dismissible = true; notification.dismissAfter = 5000;
                    notify(notification)
                    console.log(respuestaAxios);
                }
            })
            .catch(err => {
                console.log(err);
                notification.status = 'error'; notification.title = '¡Ocurrió un Error!'; notification.message = 'Sucedió un problema al hacer la conexión al servidor, vuelve a intentarlo más tarde.'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
            })
        cambiarCargando(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="Email *" {...register('correo', ReglasInput['correo'])} />
                        <small className='color-red'>{errors?.['correo']?.message}</small>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Nombre *" {...register('nombre', ReglasInput['nombre'])} />
                        <small className='color-red'>{errors?.['nombre']?.message}</small>
                    </div>
                </div>
                {mensaje === false ? <></>
                    :
                    <div className="col-lg-12">
                        <div className="form-group">
                            <textarea rows={5} className="form-control" placeholder="Tu Mensaje *" {...register('nota', ReglasInput['nota'])} />
                            <small className='color-red'>{errors?.['nota']?.message}</small>
                        </div>
                    </div>
                }
                <div className="col-lg-12 center">
                    <button className="btn btn-default" type="submit">Enviar</button>
                </div>
            </div>
        </form>
    );
}

export default FormularioContacto;