import React, { useEffect } from 'react';
import Banner from 'components/inicio/Banner';
// import Portafolio from 'components/inicio/Portafolio';
import Nosotros from 'components/inicio/Nosotros';
import ComoLoHacemos from 'components/inicio/ComoLoHacemos';
import ConoceNuestraTienda from 'components/inicio/ConoceNuestraTienda';

const Inicio = () => {
    useEffect(() => { window.scrollTo({ top: 0 }) }, [])
    return (
        <>
            <Banner />
            {/* <Portafolio /> */}
            <Nosotros />
            <ComoLoHacemos />
            <ConoceNuestraTienda />
        </>
    );
}

export default Inicio;