import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SomosUno from 'components/equipo/SomosUno';
import ListaEquipo from 'components/equipo/ListaEquipo';

const Equipo = () => {
    useEffect(() => { window.scrollTo({ top: 0 }) }, [])
    return (
        <>
            <div className="inner-banner image-team" data-aos="fade">
                <div className="container">
                    <ol className="breadcrumb">
                        <li data-aos="fade" data-aos-delay="200" >
                            <Link to="/inicio">Inicio</Link>
                        </li>
                        <li data-aos="fade" data-aos-delay="400" className="active">Equipo</li>
                    </ol>
                    <h1 data-aos="fade" data-aos-delay="600">Nuestro Equipo</h1>
                </div>
            </div>
            <SomosUno />
            <ListaEquipo />
        </>
    );
}

export default Equipo;