import React, { useEffect } from 'react';
import Img404 from 'images/404.svg';
import { useShop } from 'context/ShopContext';

const ErrorApi = () => {
    const { cambiarCargando } = useShop();
    useEffect(() => {
        window.scrollTo({ top: 0 })
        cambiarCargando(false)
    }, [cambiarCargando])

    return (
        <div className="text-center contenedor-404">
            <img src={Img404} alt="" />
            <h1>Parece que ocurrio un error...</h1>
            <p>Parece que estamos teniendo problemas por el momento. Te pedimos que vuelvas más tarde.</p>
            <div className="text-center">
                <a href="/inicio" className="text-uppercase mr-50" >Volver a Intentarlo</a>
                <a href="tel:8717220668" className="text-uppercase">Contáctanos</a>
            </div>
        </div>
    );
}

export default ErrorApi;