import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useShop } from 'context/ShopContext';
import FuncionPrecio from 'functions/FuncionPrecio';

const InfoProducto = ({ producto, id }) => {
    const { FuncionAgregarCarrito } = useShop();
    const [cantidad, cambiarCantidad] = useState(1)
    const [atributoUno, cambiarAtributoUno] = useState('false');
    console.log(producto);
    const ValidarAgregarCarrito = () => {
        if (producto.PRO_ACTIVO === false) { return alert(`El producto se encuentra inactivo actualmente`) }
        if (cantidad < 0 || cantidad === '') { return alert(`La Cantidad debe ser mayor a 0 ${producto.UNM_NOMBRE}`) }

        let ArticuloID = 0
        switch (producto.LIN_VARIANTE) {
            case 0:
                ArticuloID = producto.ART_ID
                break;
            case 1:
                if (atributoUno === 'false') { return alert('Debes seleccionar una talla') }
                const Temporal = producto.ATRIBUTO1.filter((renglon) => {
                    return renglon.ART_ATRIBUTO1 === atributoUno
                })
                ArticuloID = Temporal[0].ART_ID
                break;

            default:
                break;
        }

        const record = {
            PRO_ID: parseInt(id),
            PRO_NOMBRE: producto.PRO_NOMBRE,
            PRO_PRECIO: producto.PRO_PRECIO,
            PRO_DESCUENTO1: producto.PRO_DESCUENTO1,
            PRO_IMPUESTO: producto.PRO_IMPUESTO,
            PRO_IMAGEN: producto.IMAGENES[0].ACH_LINK,
            ART_ID: ArticuloID,
            ART_ATRIBUTO1: atributoUno === 'false' ? '' : atributoUno,
            // ART_ATRIBUTO2: atributoDos === 'false' ? '' : atributoDos,
            ART_ATRIBUTO1_NOMBRE: producto.LIN_ATRIBUTO_1,
            // ART_ATRIBUTO2_NOMBRE: producto.LIN_ATRIBUTO_2,
            UNM_NOMBRE: producto.UNM_NOMBRE,
            DOD_CANTIDAD: parseInt(cantidad),
            // FILTROS: producto.FILTROS
        }

        FuncionAgregarCarrito(record)
    }



    return (
        <div className="row">
            {/* Gallery Starts */}
            <div className="col-lg-6 col-md-6 col-sm-6 gallery" data-aos="fade-up" data-aos-delay="100">
                <img src={producto.IMAGENES ? producto.IMAGENES[0].ACH_LINK : "https://via.placeholder.com/550x550"} className="img-responsive" style={{width:'90%'}} alt="algo" />
            </div>
            {/* Gallery Ends */}
            {/* Detail Starts */}
            <div className="col-lg-6 col-md-6 col-sm-6 detail">
                <h2 className="text-uppercase" data-aos="fade-up" data-aos-delay="200">{producto.PRO_NOMBRE}</h2>
                <div className="price" data-aos="fade-up" data-aos-delay="300">{FuncionPrecio(producto.PRO_PRECIO, producto.PRO_IMPUESTO, producto.PRO_DESCUENTO1)}</div>
                <div className="description" data-aos="fade-up" data-aos-delay="400">
                    {producto.PRO_OBSERVACIONES}
                </div>
                <div className="button clearfix" data-aos="fade-up" data-aos-delay="500">
                    {
                        producto.LIN_VARIANTE === 1 &&
                        <>
                            <label htmlFor="select-talla">Talla: </label>
                            <select value={atributoUno} id="select-talla" className="select" onChange={(e) => cambiarAtributoUno(e.target.value)} disabled={!producto.PRO_ACTIVO}>
                                <option value={'false'}>Seleccionar</option>
                                {
                                    producto.ATRIBUTO1 ?
                                        producto.LIN_VARIANTE === 1 &&
                                        producto.ATRIBUTO1.map((atributo, index) => {
                                            return (
                                                <option key={index} value={atributo.ART_ATRIBUTO1}>{atributo.ART_ATRIBUTO1}</option>
                                            )
                                        })
                                        : <></>
                                }
                            </select>
                        </>
                    }
                    <div className="form-inline">
                        <div className="form-group">
                            <input type="number"
                                className="form-control"
                                value={cantidad}
                                onChange={(e) => cambiarCantidad(Math.round(e.target.value))}
                                style={{ width: 90, marginRight: 20 }}
                            />
                        </div>
                        <button type="button" onClick={() => ValidarAgregarCarrito()} className="btn btn-default">
                            AÑADIR AL CARRITO
                        </button>
                        <br className="visible-sm visible-xs" />
                        <br className="visible-sm visible-xs" />
                    </div>
                </div>
                <ul className="cats">
                    <li>Categoria:</li>
                    <li>
                        <Link to={`/tienda/c&=${producto.CAT_NOMBRE}`}>{producto.CAT_NOMBRE}</Link>
                    </li>
                </ul>
            </div>
            {/* Detail Ends */}
        </div>

    );
}

export default InfoProducto;