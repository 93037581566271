import React, { useEffect } from 'react';
import { useShop } from 'context/ShopContext';
import BannerCarrito from 'components/carrito/BannerCarrito';
import ListaCarrito from 'components/carrito/ListaCarrito';
import { Link } from 'react-router-dom';
import FormatoCantidad from 'functions/FormatoCantidad';

const Carrito = () => {
    const { carrito, subtotal } = useShop();
    useEffect(() => { window.scrollTo({ top: 0 }) }, [])

    return (
        <>
            <BannerCarrito />
            <div className="container contents shop">
                {/* Cart Grid Starts */}
                {carrito.length !== 0 ?
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cart-grid">
                                {/* Row Header Starts */}
                                <div className="hidden-xs" data-aos="fade" data-aos-delay="100">
                                    <div className="row cart-row-header">
                                        <div className="col-xs-12 col-sm-5 col-md-6 col-lg-7">
                                            <div className="row">
                                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                                                <div className="col-xs-10 col-sm-9 col-md-10 col-lg-10 left">
                                                    Productos
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-7 col-md-6 col-lg-5">
                                            <div className="row">
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">Precio</div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                    Cantidad
                                                </div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">Total</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Row Header Ends */}
                                <ListaCarrito carrito={carrito} />
                            </div>
                            {/* Proceed to Checkout Starts */}
                            <div className="row proceed-to-checkout" data-aos="fade-up" data-aos-delay="300">
                                <div className="col-lg-6 col-lg-offset-6 border">
                                    {/* <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 sub-total-price">
                                            Subtotal
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 sub-total-price right">
                                            £97.00
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 total-price">
                                            SubTotal
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 total-price right">
                                            {FormatoCantidad.format(Math.round(subtotal))}
                                        </div>
                                    </div>
                                    <div className="row text-right">
                                        <Link to="/checkout" className="btn btn-default">
                                            Realizar Pago
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* Proceed to Checkout Ends */}
                        </div>
                    </div>
                    :
                    <div className="text-center">
                        <h1>El carrito esta vacio</h1>
                        <Link to="/tienda">Ir a la tienda</Link>
                    </div>
                }

                {/* Cart Grid Ends */}
            </div>
        </>
    );
}

export default Carrito;