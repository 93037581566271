import React from 'react';
import BannerPedido from 'components/pedido/BannerPedido';
import PedidoInfo from 'components/pedido/PedidoInfo';

const Pedido = () => {
    return (
        <>
            <BannerPedido />
            <PedidoInfo />
        </>
    );
}

export default Pedido;