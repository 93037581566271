import React from 'react';
import { EliminarFiltros, ModificarFiltros } from 'components/tienda/FuncionesTienda';

const FiltrosTienda = ({ filtros, listaCategorias, cambiarFiltros }) => {

    const ListaFiltroCategorias = () => {
        let categoriaSeleccionada = filtros['categoria']
        if (categoriaSeleccionada !== '') {
            return (
                <ul>
                    <li>
                        <span onClick={() => EliminarFiltros('categoria', filtros, cambiarFiltros)}>{filtros['categoria']} <i className="icon-close icons"></i></span>
                    </li>
                </ul>
            )
        } else {
            return (
                <ul>
                    {listaCategorias.map((renglon, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => ModificarFiltros('categoria', filtros, cambiarFiltros, renglon)}>{renglon}</span>
                            </li>
                        )
                    })
                    }
                </ul>
            )
        }
    }


    return (
        <>
            <div className="categories" data-aos="fade-up" data-aos-delay="100">
                <h1>CATEGORIAS</h1>
                {listaCategorias !== undefined &&
                    ListaFiltroCategorias()
                }
            </div>
            <div className="line" />
        </>
    );
}

export default FiltrosTienda;