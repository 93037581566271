import React from 'react';
import { useParams } from 'react-router-dom';
import useObtenerDatosDocumento from 'hooks/useObtenerDatosDocumento';
import FuncionPrecio from 'functions/FuncionPrecio';
import ListaPedido from 'components/pedido/ListaPedido';

const Pedido = () => {
    const { idDoc } = useParams();
    const [datosExtras] = useObtenerDatosDocumento({ idDoc });
    console.log(datosExtras);
    return (
        <div className="container contents shop checkout">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                    {/* Billing Address Starts */}
                    <h1>
                        Tus <span>Datos</span>
                    </h1>
                    <p>
                        <b>Fecha: </b> {datosExtras.DOC_FECHA} <br />
                        <b>Nombre: </b> {datosExtras.EMP_NOMBRE} <br />
                        <b>Fecha: </b> {datosExtras.DOC_REFERENCIA} <br />
                        <b>Total: </b> {FuncionPrecio(datosExtras.DOC_TOTAL,0,0)} <br />
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-delay="400">
                    {/* Your Order Starts */}
                    <div className="cart-grid">
                        <h1><span>Pedido</span></h1>
                        <div className="row cart-row-header">
                            <div className="col-lg-8 col-md-8 col-sm-8">Productos</div>
                            <div className="col-lg-1 col-md-1 col-sm-1" />
                            <div className="col-lg-3 col-md-3 col-sm-3 right">Total</div>
                        </div>
                        <ListaPedido carrito={datosExtras.DETALLE} />
                    </div>
                </div>
            </div>
            <div className="row">
                <a className='btn btn-default' href="/inicio">Volver a Inicio</a>
            </div>
        </div>
    );
}

export default Pedido;