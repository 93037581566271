import React from 'react';

const Nosotros = () => {
    return (
        <div className="container home-intro">
            <div className="row">
                <div className="col-lg-8 col-lg-offset-2">
                    <div className="icon" data-aos="fade-up" data-aos-delay="100">
                        <img src="https://res.cloudinary.com/labouche/image/upload/v1634178734/imagenes_fijas/logotipos/isotipo_rug9uo.png" style={{width:35}} alt="" />
                    </div>
                    <h1 style={{color: '#000000'}} data-aos="fade-up" data-aos-delay="200">
                        LABOUCHE
                    </h1>
                    <div className="description text-justify" data-aos="fade-up" data-aos-delay="300">
                        Desde 2012, La Bouche nace bajo un solo concepto, único y expresivo. Descubriendo su inspiración en algo que solo a las mujeres las hace únicas, sus labios. Aquellos que le dan una esencia diferente, hermosa y característica a cada mujer. Aquellos, que son los que las diferencia de todas las personas y reflejan su personalidad y carisma.
                    </div>
                    <div className="description text-justify" data-aos="fade-up" data-aos-delay="400">
                        Enamorados de la belleza en todo su esplendor, admiramos las distintas formas, tamaños, colores y expresiones de los labios en todas las culturas, sin importar color, creencias, preferencia, religión y cultura.
                    </div>
                    <div className="description text-justify" data-aos="fade-up" data-aos-delay="500">
                        Hemos logrado reflejar lo hermoso de los labios en piezas excepcionales, transformándolas en joyería fina.
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Nosotros;