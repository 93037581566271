import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { ShopProvider } from 'context/ShopContext';
import 'App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Animaciones
import Aos from 'aos';
import 'aos/dist/aos.css'

// Notificaciones
import { NotificationsProvider, setUpNotifications } from 'reapop';
import Notificacion from 'core/general/Notificacion';

// Funciones Carrito
import { RecuperarCarrito, GuardarCarritoLocal, ActualizarSubtotal } from 'functions/FuncionesCarrito';

// Componentes
import Menu from 'core/menu/Menu';
import Footer from 'core/footer/Footer';
import ErrorApi from 'core/general/ErrorApi';
import Inicio from 'components/inicio/Inicio';
import Equipo from 'components/equipo/Equipo';
import Contacto from 'components/contacto/Contacto';
import Tienda from 'components/tienda/Tienda';
import Producto from 'components/producto/Producto';
import Carrito from 'components/carrito/Carrito';
import Checkout from 'components/checkout/Checkout';
import Pedido from 'components/pedido/Pedido';


import Overlay from 'core/general/Overlay';

const App = () => {
  const [carrito, cambiarCarrito] = useState(RecuperarCarrito);
  const [subtotal, cambiarSubtotal] = useState(0);
  const [cargando, cambiarCargando] = useState(false);

  useEffect(() => {
    GuardarCarritoLocal(carrito)
    ActualizarSubtotal(carrito, cambiarSubtotal)
  }, [carrito]);

  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true
    }
  });

  useEffect(() => {
    Aos.init({ once: false, duration: 600, easing: 'ease-in-out' });
  }, [])

  return (
    <>
      <HelmetProvider>
        <NotificationsProvider>
          <ShopProvider carrito={carrito} subtotal={subtotal} cambiarCarrito={cambiarCarrito} cambiarCargando={cambiarCargando}>
            <BrowserRouter>
              <Menu />
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/inicio" />
                </Route>
                <Route path="/inicio" exact>
                  <Helmet>
                    <title>Labouche - Joyeria Fina</title>
                  </Helmet>
                  <Inicio />
                </Route>
                <Route path="/equipo" exact>
                  <Helmet>
                    <title>Labouche - Equipo</title>
                  </Helmet>
                  <Equipo />
                </Route>
                <Route path="/contacto" exact>
                  <Helmet>
                    <title>Labouche - Contacto</title>
                  </Helmet>
                  <Contacto />
                </Route>
                <Route path="/tienda/:filtrouno?/:filtrodos?/:filtrotres?/:filtrocuatro?" exact>
                  <Helmet>
                    <title>Labouche - Tienda</title>
                  </Helmet>
                  <Tienda />
                </Route>
                <Route path="/producto/:id">
                  <Producto />
                </Route>
                <Route path="/carrito" exact>
                  <Helmet>
                    <title>Carrito</title>
                  </Helmet>
                  <Carrito />
                </Route>
                <Route path="/checkout" exact>
                  <Helmet>
                    <title>CheckOut</title>
                  </Helmet>
                  <Checkout />
                </Route>
                <Route path="/error" exact>
                  <Helmet>
                    <title>Página de Error</title>
                  </Helmet>
                  <ErrorApi />
                </Route>
                <Route path="/pedido/:idDoc?" exact>
                  <Helmet>
                    <title>Pedido</title>
                  </Helmet>
                  <Pedido />
                </Route>

              </Switch>
              <Notificacion />
              <Footer />

              {/* {menuCelAbierto && <OverlayMenuCel cambiarMenuCelAbierto={cambiarMenuCelAbierto} />} */}
              {cargando && <Overlay />}
            </BrowserRouter>
          </ShopProvider>
        </NotificationsProvider>
      </HelmetProvider>
    </>
  );
}

export default App;