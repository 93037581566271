import React, { useEffect, useRef, useState } from 'react';
import Banner from 'components/tienda/Banner';
import GridProductos from 'components/tienda/GridProductos';
import FiltrosTienda from 'components/tienda/FiltrosTienda';
import useObtenerListaProductos from 'hooks/useObtenerListaProductos';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import Spinner from 'core/general/Spinner';

const Tienda = () => {
    //* SACAMOS LOS FILTROS DE LA URL
    const { filtrouno, filtrodos, filtrotres, filtrocuatro } = useParams();

    //* FUNCION PARA PONER CADA FILTRO EN SU POSICION
    const CargarFiltrosUrl = (tipo) => {
        if (filtrouno === undefined && filtrodos === undefined && filtrotres === undefined && filtrocuatro === undefined) {
            return tipo === 'p&=' ? 1 : '';
        } else {
            const ArrayFiltrosUrl = [filtrouno, filtrodos, filtrotres, filtrocuatro]
            var respuesta = tipo === 'p&=' ? 1 : ''
            ArrayFiltrosUrl.forEach((filtro) => {
                if (filtro !== undefined && filtro.includes(tipo)) {
                    respuesta = filtro.replace(tipo, '')
                }
            })
            return respuesta
        }
    }

    //* CREAMOS EL STATE DE FILTRO
    const [filtros, cambiarFiltros] = useState({
        categoria: CargarFiltrosUrl('c&='), linea: CargarFiltrosUrl('l&='), texto: CargarFiltrosUrl('t&='),
        pagina: CargarFiltrosUrl('p&='), atributo1: '', atributo2: '', atributo3: '', atributo4: '', atributo5: '', atributo6: ''
    })

    const cantidadRender = useRef(0)
    const [cargandoProductos, cambiarCargandoProductos] = useState(true)
    const [listaDatos] = useObtenerListaProductos({ filtros, cambiarCargandoProductos });
    const history = useHistory();

    //* SUBIMOS LA PANTALLA HASTA ARRIBA CADA DE CAMBIAN LOS DATOS
    useEffect(() => { window.scrollTo({ top: 0, }) }, [filtrouno, filtrodos, filtrotres, filtrocuatro])

    //* AQUI CAMBIAMOS LA URL CADA VEZ QUE CAMBIA UN FILTRO, PERO IGNORA LA PRIMERA VEZ QUE SE RENDERIZA LA PÁGINA
    useEffect(() => {
        if (cantidadRender.current !== 0) {
            const NuevaURL = (filtros.categoria !== '' ? `/c&=${filtros.categoria}` : '') +
                (filtros.linea !== '' ? `/l&=${filtros.linea}` : '') +
                (filtros.texto !== '' ? `/t&=${filtros.texto}` : '') +
                (filtros.pagina !== 1 ? `/p&=${filtros.pagina}` : '')
            const newPath = generatePath('/tienda' + NuevaURL)
            return history.push(newPath)
        }
        cantidadRender.current = cantidadRender.current + 1;
    }, [filtros, history])

    return (
        <>
            <Banner />
            {/* CONTENEDOR */}
            <div className="container contents store">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 sidebar">
                        {/* FILTROS */}
                        <FiltrosTienda listaCategorias={listaDatos.CATEGORIAS} filtros={filtros} cambiarFiltros={cambiarFiltros} />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                        {cargandoProductos ?
                            <Spinner />
                            :
                            <>
                                {/* LISTADO PRODUCTOS */}
                                <GridProductos listaProductos={listaDatos.PRODUCTOS} />

                                {/* PAGINACION */}
                                {/* <div className="row">
                                    <div className="col-lg-12 pagging">
                                        <ul className="pager">
                                            <li className="previous">
                                                <a href="algo.html" aria-label="Previous">
                                                    <span aria-hidden="true" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="algo.html">1</a>
                                            </li>
                                            <li className="active">
                                                <a href="algo.html">2</a>
                                            </li>
                                            <li>
                                                <a href="algo.html">3</a>
                                            </li>
                                            <li>
                                                <a href="algo.html">4</a>
                                            </li>
                                            <li>
                                                <a href="algo.html">5</a>
                                            </li>
                                            <li className="next">
                                                <a href="algo.html" aria-label="Next">
                                                    <span aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tienda;