import React from 'react';
import FormularioContacto from 'core/general/FormularioContacto';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();

    return (
        <>
            {location.pathname !== '/error' &&
                <footer className="parallax-1">
                    {/* Contact Info Starts */}
                    <div className="contact-info">
                        <div className="container">
                            <div className="row">
                                {/* Address Starts */}
                                <div className="col-lg-4 col-md-4 col-sm-4 address" data-aos="fade" data-aos-delay="100">
                                    <div className="footer-logo">
                                        <img src="https://res.cloudinary.com/labouche/image/upload/v1634178734/imagenes_fijas/logotipos/logotipo-blanco_wmzkvh.png" className="img-responsive" alt="logotipo-labouche" />
                                    </div>
                                    <address>
                                        <h5>CORPORATIVO Y CENTRO DE DISTRIBUCIÓN</h5>
                                        <strong>
                                            Francisco J. Lozano #66 Col. Nueva los Ángeles <br />
                                            Torreón, Coah. México
                                        </strong>
                                        <div className="phone">
                                            <a href="tel:8713951896">+52 (871) 3951896</a>
                                        </div>
                                        <div>
                                            <a href="mailto:info@labouche.com.mx">info@labouche.com.mx</a>
                                        </div>
                                        <div className="font-size-20 pt-20">
                                            <a href="https://www.instagram.com/labouchemx/" target="_blank" rel="noreferrer">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                            <a href="https://www.facebook.com/labouche.mexico" target="_blank" rel="noreferrer">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="https://vm.tiktok.com/ZM88HRyfV/" target="_blank" rel="noreferrer">
                                                <i className="fab fa-tiktok"></i>
                                            </a>
                                        </div>
                                    </address>

                                </div>
                                {/* Get in Touch Starts */}
                                <div className="col-lg-7 col-md-7 col-sm-7 get-in-touch col-md-offset-1" data-aos="fade" data-aos-delay="300">
                                    <h1>¿QUIERES RECIBIR INFORMACIÓN Y NOTICIAS NUEVAS SOBRE LABOUCHE?</h1>
                                    <FormularioContacto mensaje={false} />
                                </div>
                                {/* Get in Touch Ends */}
                            </div>
                        </div>
                    </div>
                    {/* Contact Info Ends */}
                    {/* Copyright Starts */}
                    <div className="copyright light-grey-bg">
                        <div className="container">
                            <div className="row text-center">
                                © 2021 LABOUCHE  - JOYERÍA FINA
                            </div>
                        </div>
                    </div>
                    {/* Copyright Starts */}
                </footer>
            }
        </>

    );
}

export default Footer;