import React from 'react';
import axios from 'axios';
import { useShop } from 'context/ShopContext';
import ListaPedido from 'components/checkout/ListaPedido';
import { useForm } from 'react-hook-form';
import ReglasInput from 'components/checkout/ReglasInput';
import { ProcesarCarritoParaPedido } from 'functions/FuncionesCarrito';
import FuncionPrecio from 'functions/FuncionPrecio';
import { useNotifications } from 'reapop';

const Formulario = () => {
    const { carrito } = useShop();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { notify } = useNotifications();
    const { cambiarCargando } = useShop();
    const { CarritoProcesado, Total } = ProcesarCarritoParaPedido(carrito, true)

    const onSubmit = async (data) => {
        cambiarCargando(true)
        const notification = notify({ title: 'Guardando Cotización...', message: 'Estamos procesando la solicitud de cotización, espera un momento.', dismissible: false, status: 'loading' })
        const formulario = {
            nombre: data.nombre, correo: data.correo, celular: data.celular, calle: data.calle, ciudad: data.ciudad, estado: data.estado, cp: data.cp, colonia: data.colonia,
            carrito: CarritoProcesado, total: Total,
            nota: `Dirección de Envio: ${data.calle}, ${data.colonia}, ${data.ciudad}, ${data.estado}, ${data.cp}. || ${data.nota !== '' ? data.nota : 'Sin notas añadidas.'} `
        }
        console.log(formulario);
        const URL = `${process.env.REACT_APP_CONFIG_BASE_URL}/tienda_carro/${process.env.REACT_APP_CONFIG_EMPRESA}/3`
        await axios.post(URL, formulario)
            .then(res => {
                const respuestaAxios = res.data;
                // console.log(respuestaAxios);
                if (respuestaAxios.ESTATUS === "OK") {
                    notification.status = 'success'; notification.title = '¡Todo listo para realizar tu pago!'; notification.message = 'Ya tenemos todo listo para que realices tu pago.'; notification.dismissible = true; notification.dismissAfter = 5000;
                    notify(notification)
                    cambiarCargando(false)
                    const script = document.createElement('script');
                    script.src = "https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js";
                    script.dataset['preferenceId'] = respuestaAxios.ID;

                    document.body.appendChild(script);
                    setTimeout(() => {
                        var btn = document.getElementsByClassName("mercadopago-button");
                        btn[0].click();
                    }, 500);
                } else if (respuestaAxios.ESTATUS === "ERROR") {
                    notification.status = 'error'; notification.title = '¡Hubo un Problema!'; notification.message = 'No se pudo procesar la información en el servidor'; notification.dismissible = true; notification.dismissAfter = 5000;
                    notify(notification)
                    cambiarCargando(false)
                }
            })
            .catch(err => {
                console.log(err);
                notification.status = 'error'; notification.title = '¡Ocurrió un Error!'; notification.message = 'Sucedió un problema al hacer la conexión al servidor, vuelve a intentarlo.'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                cambiarCargando(false)
            })
    }

    return (
        <div className="container contents shop checkout">
            <div className="row">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                        {/* Billing Address Starts */}
                        <h1>
                            Ingresa tus <span>Datos</span>
                        </h1>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Nombre Completo" {...register('nombre', ReglasInput['nombre'])} />
                            {errors?.['nombre'] && <small className='color-red'>{errors?.['nombre']?.message}</small>}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Correo Electrónico" {...register('correo', ReglasInput['correo'])} />
                                    {errors?.['correo'] && <small className='color-red'>{errors?.['correo']?.message}</small>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Celular" {...register('celular', ReglasInput['celular'])} />
                                    {errors?.['celular'] && <small className='color-red'>{errors?.['celular']?.message}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Calle y Número" {...register('calle', ReglasInput['calle'])} />
                            {errors?.['calle'] && <small className='color-red'>{errors?.['calle']?.message}</small>}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Colonia" {...register('colonia', ReglasInput['colonia'])} />
                                    {errors?.['colonia'] && <small className='color-red'>{errors?.['colonia']?.message}</small>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Ciudad" {...register('ciudad', ReglasInput['ciudad'])} />
                                    {errors?.['ciudad'] && <small className='color-red'>{errors?.['ciudad']?.message}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Estado" {...register('estado', ReglasInput['estado'])} />
                                    {errors?.['estado'] && <small className='color-red'>{errors?.['estado']?.message}</small>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="C.P." {...register('cp', ReglasInput['cp'])} />
                                    {errors?.['cp'] && <small className='color-red'>{errors?.['cp']?.message}</small>}
                                </div>
                            </div>
                        </div>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Agrega alguna Nota"
                            {...register('nota')}
                        />
                        {/* Billing Address Ends */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-delay="400">
                        {/* Your Order Starts */}
                        <div className="cart-grid">
                            <h1><span>Pedido</span></h1>
                            <div className="row cart-row-header">
                                <div className="col-lg-8 col-md-8 col-sm-8">Productos</div>
                                <div className="col-lg-1 col-md-1 col-sm-1" />
                                <div className="col-lg-3 col-md-3 col-sm-3 right">Total</div>
                            </div>
                            <ListaPedido carrito={carrito} />
                            <div className="line" />
                            < div className="row cart-row" >
                                <div className="col-lg-8 col-md-8 col-sm-8 product-name">
                                    Envio Nacional en México
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 quantity"></div>
                                <div className="col-lg-3 col-md-3 col-sm-3 product-price right">
                                    $150.00
                                </div>
                            </div>


                            <div className="row proceed-to-checkout">
                                <div className="col-lg-12 border">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 total-price">
                                            Total
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 total-price right">
                                            {FuncionPrecio(Total,0,0)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Your Order Ends */}
                        <div className="line" />
                        {/* Place Order Starts */}
                        <div className="payment-method">
                            <button type="submit" className="btn btn-default">
                                Realizar Pago
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row">
                <div className="col-lg-12">

                    {/* Place Order Ends */}
                </div>
            </div>
        </div>

    );
}

export default Formulario;