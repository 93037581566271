import React from 'react';

const ListaEquipo = () => {
    return (
        <div className="container contents team">
            <div className="row">
                {/* Block Starts */}
                <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-right" data-aos-delay="100">
                    <div className="picture">
                        <img src="https://res.cloudinary.com/labouche/image/upload/v1634431044/imagenes_fijas/team/foto-jos_tftgok.jpg" className="img-responsive" alt="algo" />
                    </div>
                    <div className="info">
                        <div className="name">
                            <a href="team-person.html">JOS ALVAREZ</a>
                        </div>
                        <div className="designation">FOTOGRAFÍA </div>
                    </div>
                </div>
                {/* Block Ends */}
                {/* Block Starts */}
                <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-left" data-aos-delay="200">
                    <div className="picture">
                        <img src="https://res.cloudinary.com/labouche/image/upload/v1633810963/imagenes_fijas/team/ana_nwt2b0.jpg" className="img-responsive" alt="algo" />
                    </div>
                    <div className="info">
                        <div className="name">
                            <a href="team-person.html">ANNA BARRAZA</a>
                        </div>
                        <div className="designation">COPYWRITER</div>
                    </div>
                </div>
                {/* Block Ends */}
                {/* Block Starts */}
                <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-right" data-aos-delay="300">
                    <div className="picture">
                        <img src="https://res.cloudinary.com/labouche/image/upload/v1633810963/imagenes_fijas/team/diego_dnttcc.jpg" className="img-responsive" alt="algo" />
                    </div>
                    <div className="info">
                        <div className="name">
                            <a href="team-person.html">DIEGO JAUREGUI</a>
                        </div>
                        <div className="designation">MERCADOTÉCNIA</div>
                    </div>
                </div>
                {/* Block Ends */}
                {/* Block Starts */}
                <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-left" data-aos-delay="400">
                    <div className="picture">
                        <img src="https://res.cloudinary.com/labouche/image/upload/v1633810963/imagenes_fijas/team/jesus_lyy6m7.jpg" className="img-responsive" alt="algo" />
                    </div>
                    <div className="info">
                        <div className="name">
                            <a href="team-person.html">JESÚS RIVERA</a>
                        </div>
                        <div className="designation">DISEÑO GRÁFICO</div>
                    </div>
                </div>
                {/* Block Ends */}
            </div>
        </div>

    );
}

export default ListaEquipo;