import React from 'react';
import FormularioContacto from 'core/general/FormularioContacto';

const ElementosContacto = () => {
    return (
        <div className="contact-us">
            {/* Contact Info Starts */}
            <div className="container contact-info">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h1 data-aos="fade-up" data-aos-delay="100">
                            INFORMACIÓN DE <span>CONTACTO</span>
                        </h1>
                        <div className="description" data-aos="fade-up" data-aos-delay="200">
                            Si tienes alguna pregunta, quieres realizar alguna reclamación o simplemente comentarnos algo, no dudes en contáctarnos. Con todo gusto te atenderemos. Estamos para servirte.
                        </div>
                        <div className="description">
                            <h3 data-aos="fade-up" data-aos-delay="300">Redes Sociales</h3>
                            <div className="font-size-20" data-aos="fade-up" data-aos-delay="400">
                                <a href="https://www.instagram.com/labouchemx/" target="_blank" rel="noreferrer">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.facebook.com/labouche.mexico" target="_blank" rel="noreferrer">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                                <a href="https://vm.tiktok.com/ZM88HRyfV/" target="_blank" rel="noreferrer">
                                <i className="fab fa-tiktok"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon">
                                    <i className="icon-call-end icons" />
                                </div>
                                <div className="caption">
                                    <a href="tel:87713951896">(871) 395 1896</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 block" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon">
                                    <i className="icon-envelope-open icons" />
                                </div>
                                <div className="caption">
                                    <a href="mailto:info@labouche.com.mx">info@labouche.com.mx</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /. Contact Info Ends */}
            {/* Google Map Starts */}
            <div className="container-fluid">
                <div className="row no-gutter-12">
                    <div className="col-lg-12">
                        <div className="google-map embed-container">
                            <iframe
                                // style={{ pointerEvents: "none" }}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d899.8449893316615!2d-103.44777107076344!3d25.559022808849114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fda2b3c014937%3A0xba627bf4efc2c049!2sFrancisco%20Lozano%2074%2C%20Nueva%20los%20%C3%81ngeles%2C%2027140%20Torre%C3%B3n%2C%20Coah.!5e0!3m2!1ses-419!2smx!4v1632790690380!5m2!1ses-419!2smx"
                                height={500}
                                allowFullScreen
                                title='map'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* /. Google Map Ends */}
            {/* Contact Form Starts */}
            <div className="container contact-form" data-aos="fade-up" data-aos-delay="200">
                <h1 className="titulo-contacto">Envíanos un <span>Mensaje</span></h1>
                <FormularioContacto />
            </div>
            {/* /. Contact Form Starts */}
        </div>

    );
}

export default ElementosContacto;