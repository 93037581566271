import React from 'react';
import { Link } from 'react-router-dom';

const ConoceNuestraTienda = () => {
    return (
        <div className="parallax-conoce-tienda">
            <div className="container newsletter">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-5">
                        <h1 data-aos="fade" data-aos-delay="100">
                            CONOCE NUESTRA <span>TIENDA</span>
                        </h1>
                        <div className="short-description" data-aos="fade" data-aos-delay="300">
                            Visita nuestro catálogo y conoce nuestros productos. Compra fácil y rápido.
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-5 col-lg-offset-1">
                        <div className="form form-inline">
                            <Link to="/tienda" className="btn btn-default" data-aos="fade" data-aos-delay="400">
                                COMPRAR AHORA
                            </Link>
                            <Link to="/contacto" className="btn btn-default" data-aos="fade" data-aos-delay="500">
                                CONTÁCTANOS
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConoceNuestraTienda;