import React from 'react';
import { Link } from 'react-router-dom';

const BannerCarrito = () => {
    return (
        <div className="inner-banner image-productos" data-aos="fade">
            <div className="container">
                <ol className="breadcrumb">
                    <li data-aos="fade" data-aos-delay="200"><Link to="/inicio">Home</Link></li>
                    <li data-aos="fade" data-aos-delay="400"><Link to="/carrito">Carrito</Link></li>
                </ol>
                <h1 data-aos="fade" data-aos-delay="600">Checkout</h1>
            </div>
        </div>
    );
}

export default BannerCarrito;