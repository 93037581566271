import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ElementosContacto from 'components/contacto/ElementosContacto';

const Contacto = () => {
    useEffect(() => { window.scrollTo({ top: 0 }) }, [])
    return (
        <>
            <div className="inner-banner image-contacto" data-aos="fade">
                <div className="container">
                    <ol className="breadcrumb">
                        <li data-aos="fade" data-aos-delay="200">
                            <Link to="/inicio">Inicio</Link>
                        </li>
                        <li data-aos="fade" data-aos-delay="400" className="active">Contacto</li>
                    </ol>
                    <h1 data-aos="fade" data-aos-delay="600">CONTÁCTANOS</h1>
                </div>
            </div>
            <ElementosContacto />
        </>
    );
}

export default Contacto;