import { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useObtenerProducto = ({ id, cambiarCargandoProductos }) => {
    const [producto, cambiarProducto] = useState({});
    const history = useHistory();

    useEffect(() => {
        const URL = `${process.env.REACT_APP_CONFIG_BASE_URL}tienda_get_producto/${process.env.REACT_APP_CONFIG_EMPRESA}/tienda/${id}`
        // console.log(URL);
        const PeticionDatos = () => {
            cambiarCargandoProductos(true)
            axios.get(URL)
                .then(res => {
                    const respuesta = res.data;
                    cambiarProducto(respuesta);
                    setTimeout(() => {
                        cambiarCargandoProductos(false)
                    }, 500)

                })
                .catch(err => {
                    console.log(err);
                    setTimeout(() => {
                        history.push('/error')
                    }, 1000)
                })
        }

        return PeticionDatos();
    }, [id, cambiarCargandoProductos, history])

    return [producto]
}

export default useObtenerProducto;